<template>
<section class="bg-light py-3 py-md-5 py-xl-8">
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
        <h2 class="mb-4 display-5 text-center">Mais eficiência, menos preocupações!</h2>
        <p class="text-secondary mb-5 text-center lead fs-4">Transforme sua infraestrutura de TI em uma vantagem competitiva com nossa consultoria especializada, garantindo eficiência, segurança e inovação para o seu negócio.</p>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-12 mx-auto">
          <a href="https://wa.me/5511916148663?text=Ol%C3%A1%2C%20gostaria%20de%20conhecer%20um%20pouco%20mais%20as%20solu%C3%A7%C3%B5es%20de%20voc%C3%AAs." class="btn btn-primary btn-lg btn-custom" type="button">Saiba mais</a>
      </div>
    </div>
  </div>
</section>
</template>

<script>
    export default {
        name: "SectionCTA",
    }
</script>