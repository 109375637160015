<template>
<header>
<section class="py-3 py-lg-5 py-xl-8 mt-3">
  <div class="container overflow-hidden">
    <div class="row gy-5 gy-lg-0 align-items-lg-center justify-content-lg-between">
      <div class="col-12 col-lg-6 order-1 order-lg-0">
        <h2 class="display-3 fw-bold mb-3">Soluções Tecnológicas Especializadas</h2>
        <p class="fs-4 mb-5">Somos uma consultoria especializada em implantação, manutenção e gerenciamento de infraestrutura de TI. Nosso foco é oferecer soluções práticas e integradas, visando resolver problemas e aprimorar continuamente os processos da sua empresa.</p>
        <div class="d-grid gap-2 d-sm-flex">
          <a href="https://wa.me/5511916148663?text=Ol%C3%A1%2C%20gostaria%20de%20conhecer%20um%20pouco%20mais%20as%20solu%C3%A7%C3%B5es%20de%20voc%C3%AAs." class="btn btn-primary btn-lg btn-custom">Saiba mais</a>
        </div>
      </div>
      <div class="col-12 col-lg-5 text-center">
        <div class="position-relative">
          <div class="bsb-circle border border-4 border-warning position-absolute top-50 start-10 translate-middle z-1"></div>
          <div class="bsb-circle bg-primary position-absolute top-50 start-50 translate-middle" style="--bsb-cs: 460px;"></div>
          <div class="bsb-circle border border-4 border-warning position-absolute top-10 end-0 z-1" style="--bsb-cs: 100px;"></div>
          <img class="img-fluid position-relative z-2" loading="lazy" src="../assets/img/banner.jpg" alt="A Digital Agency Specialized in AI and Web 3.0">
        </div>
      </div>
    </div>
  </div>
</section>
</header>
</template>
    
    <script>
    export default {
        name: "JumboTron",
    }
    </script>
    
    <style scoped>
    
    </style>