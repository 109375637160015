<template>
<footer class="py-3 bg-light">
<div class="py-6 py-md-8 py-xl-10">
  <div class="container">
    <div class="row gy-3 mb-3">
      <div class="col-12">
        <div class="footer-logo-wrapper text-center">
          <a href="#!">
            <img src="../assets/img/logo.svg" alt="WRM Logo" width="153" height="60">
          </a>
        </div>
      </div>
      <div class="col-12">
        <div class="link-wrapper">
          <ul class="m-0 list-unstyled d-flex justify-content-center gap-3">
            <li>
              <a href="#" class="text-decoration-none rover">
                Home
              </a>
            </li>
            <li>
              <a href="#services" class="text-decoration-none rover">
                Serviços
              </a>
            </li>
            <li>
              <a href="#about" class="text-decoration-none rover">
                Sobre
              </a>
            </li>
            <li>
              <a href="#contact" class="text-decoration-none rover">
                Contato
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Copyright - Bootstrap Brain Component -->
<div>
    <div class="container border-top pt-3 pt-md-4">
      <div class="row">
        <div class="col-12">
          <div class="copyright-wrapper mb-1 fs-7 text-center">
            &copy; 2024. Todos os direitos reservados.
          </div>
        </div>
      </div>
    </div>
  </div>

</footer>
</template>

<script>
    export default {
    name: "SectionFooter",
}
</script>
