<template>
<section class="py-3 py-md-5 py-xl-8" id="about">
  <div class="container overflow-hidden">
    <div class="row gy-4 gy-md-5 gy-lg-0 align-items-center">
      <div class="col-12 col-lg-5">
        <div class="row">
          <div class="col-12 col-xl-11">
            <h1 class="mb-3 mb-xl-4 text-uppercase">Conheça a WRM</h1>
            <h3 class="mb-3 mb-xl-4">Junte-se a nós e descubra como podemos transformar sua infraestrutura!</h3>
            <p class="mb-3 mb-xl-4">Somos especializados em infraestrutura de TI, oferecendo soluções robustas e personalizadas para atender às necessidades do seu negócio. Nosso compromisso é proporcionar um ambiente tecnológico seguro e eficiente, permitindo que você foque no crescimento da sua empresa.</p>
            <a href="https://wa.me/5511916148663?text=Ol%C3%A1%2C%20gostaria%20de%20conhecer%20um%20pouco%20mais%20as%20solu%C3%A7%C3%B5es%20de%20voc%C3%AAs." class="btn btn-primary btn-lg btn-custom">Fale com nosso especialista</a>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-7 overflow-hidden">
        <div class="row gy-4">
          <div class="col-12 col-sm-6">
            <div class="card border-0 border-bottom border-primary shadow-sm" style="border-color: #FF6600 !important;">
              <div class="card-body text-center p-4 p-xxl-5">
                <svg viewBox="0 0 24 24" fill="none" height="60" width="60" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M18 7H18.01M15 7H15.01M18 17H18.01M15 17H15.01M6 10H18C18.9319 10 19.3978 10 19.7654 9.84776C20.2554 9.64477 20.6448 9.25542 20.8478 8.76537C21 8.39782 21 7.93188 21 7C21 6.06812 21 5.60218 20.8478 5.23463C20.6448 4.74458 20.2554 4.35523 19.7654 4.15224C19.3978 4 18.9319 4 18 4H6C5.06812 4 4.60218 4 4.23463 4.15224C3.74458 4.35523 3.35523 4.74458 3.15224 5.23463C3 5.60218 3 6.06812 3 7C3 7.93188 3 8.39782 3.15224 8.76537C3.35523 9.25542 3.74458 9.64477 4.23463 9.84776C4.60218 10 5.06812 10 6 10ZM6 20H18C18.9319 20 19.3978 20 19.7654 19.8478C20.2554 19.6448 20.6448 19.2554 20.8478 18.7654C21 18.3978 21 17.9319 21 17C21 16.0681 21 15.6022 20.8478 15.2346C20.6448 14.7446 20.2554 14.3552 19.7654 14.1522C19.3978 14 18.9319 14 18 14H6C5.06812 14 4.60218 14 4.23463 14.1522C3.74458 14.3552 3.35523 14.7446 3.15224 15.2346C3 15.6022 3 16.0681 3 17C3 17.9319 3 18.3978 3.15224 18.7654C3.35523 19.2554 3.74458 19.6448 4.23463 19.8478C4.60218 20 5.06812 20 6 20Z" stroke="#003366" stroke-width="0.9600000000000002" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                <h4 class="mb-4 mt-2">Maximize Recursos</h4>
                <p class="mb-4 text-secondary">Nossa expertise em virtualização de servidores permite otimizar a utilização de recursos, reduzindo custos operacionais e aumentando a flexibilidade e escalabilidade do seu ambiente de TI com soluções eficientes!</p>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="card border-0 border-bottom border-primary shadow-sm" style="border-color: #FF6600 !important;">
              <div class="card-body text-center p-4 p-xxl-5">
                <svg viewBox="0 0 24 24" fill="none" height="60" width="60" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M9.5 20H6.2C5.0799 20 4.51984 20 4.09202 19.782C3.71569 19.5903 3.40973 19.2843 3.21799 18.908C3 18.4802 3 17.9201 3 16.8V7.2C3 6.0799 3 5.51984 3.21799 5.09202C3.40973 4.71569 3.71569 4.40973 4.09202 4.21799C4.51984 4 5.0799 4 6.2 4H15.8C16.9201 4 17.4802 4 17.908 4.21799C18.2843 4.40973 18.5903 4.71569 18.782 5.09202C19 5.51984 19 6.0799 19 7.2V8H3M3 12H11V8M3 16H9M7 4V8M7 12V16M15 4V8M19.8284 19.8284C18.2663 21.3905 15.7337 21.3905 14.1716 19.8284C13.3905 19.0474 13 18.0237 13 17C13 15.9763 13.3905 14.9526 14.1716 14.1716C14.1716 14.1716 14.5 15 15.5 15.5C15.5 14.5 15.75 13 16.9929 12C18 13 19.0456 13.3887 19.8284 14.1716C20.6095 14.9526 21 15.9763 21 17C21 18.0237 20.6095 19.0474 19.8284 19.8284Z" stroke="#003366" stroke-width="0.9600000000000002" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                <h4 class="mb-4 mt-2">Segurança Robusta</h4>
                <p class="mb-4 text-secondary">Oferecemos soluções de segurança abrangentes para proteger seus dados e sistemas contra ameaças. Com nossa abordagem proativa, garantimos a integridade das suas informações e a tranquilidade do seu negócio.</p>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="card border-0 border-bottom border-primary shadow-sm" style="border-color: #FF6600 !important;">
              <div class="card-body text-center p-4 p-xxl-5">
                <svg viewBox="0 0 24 24" fill="none" height="60" width="60" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M3 13H8.5L10 15L12 9L14 13H17M6.2 19H17.8C18.9201 19 19.4802 19 19.908 18.782C20.2843 18.5903 20.5903 18.2843 20.782 17.908C21 17.4802 21 16.9201 21 15.8V8.2C21 7.0799 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V15.8C3 16.9201 3 17.4802 3.21799 17.908C3.40973 18.2843 3.71569 18.5903 4.09202 18.782C4.51984 19 5.07989 19 6.2 19Z" stroke="#003366" stroke-width="0.9600000000000002" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                <h4 class="mb-4">Monitoração Contínua</h4>
                <p class="mb-4 text-secondary">Nosso serviço de monitoramento de ativos de TI proporciona visibilidade em tempo real sobre o desempenho e a saúde da sua infraestrutura. Identificamos problemas antes que se tornem críticos, garantindo a continuidade dos seus serviços.</p>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="card border-0 border-bottom border-primary shadow-sm" style="border-color: #FF6600 !important;"> 
              <div class="card-body text-center p-4 p-xxl-5">
                <svg viewBox="0 0 24 24" fill="none" height="52" width="52" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M3 11.3C3 6.32949 7.02944 2.30005 12 2.30005C16.9706 2.30005 21 6.32949 21 11.3M3 11.3H5C6.10457 11.3 7 12.1955 7 13.3V15.3C7 16.4046 6.10457 17.3 5 17.3M3 11.3V16.3C3 16.8523 3.44772 17.3 4 17.3H5M21 11.3H19C17.8954 11.3 17 12.1955 17 13.3V15.3C17 16.4046 17.8954 17.3 19 17.3H20C20.5523 17.3 21 16.8523 21 16.3V11.3ZM5 17.3V18.3C5 19.4046 5.89543 20.3 7 20.3H9M9 20.3C9 21.1285 9.67157 21.8 10.5 21.8H11.5C12.3284 21.8 13 21.1285 13 20.3C13 19.4716 12.3284 18.8 11.5 18.8H10.5C9.67157 18.8 9 19.4716 9 20.3Z" stroke="#003366" stroke-width="0.9600000000000002" stroke-linecap="round"></path> </g></svg>
                <h4 class="mb-4 mt-2">Suporte Especializado</h4>
                <p class="mb-4 text-secondary">Contamos com nosso suporte especializado, pronto para atender suas necessidades com agilidade e eficácia. Estamos aqui para resolver problemas, responder dúvidas e garantir que sua operação funcione sem interrupções.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
</template>

<script>
        export default {
        name: "SectionAbout",
    }
</script>